<div>
  @if (form && dataRecieved) {
    <div class="row-with-title">
      <div class="col-title">
        {{ 'menu.keyQuestions' | transloco }}
      </div>

      <div class="col-add-button">
        <app-button
          class="after-control"
          icon="add"
          [type]="btnTypes.Flat"
          label="{{ 'buttons.addKeyQuestion' | transloco }}"
          (onClick)="addQuestion()"
          [size]="btnSizes.Basic"
        ></app-button>
      </div>
    </div>

    <div
      [ngClass]="{ 'visible-flex': isAnySelected && filteredQuestionsCount }"
      class="row-adaptive operations-btns edit-fieldset"
    >
      <span class="edit-fieldset-legend">{{ 'labels.editFieldsetLegend' | transloco }}</span>

      <div class="col-adaptive operation-btn-col">
        <app-button
          [icon]="'stacks'"
          [type]="btnTypes.Icon_Stroked"
          [size]="btnSizes.InOperationRow"
          [label]="'buttons.editDocType' | transloco"
          (onClick)="bulkEditDocTypes()"
        ></app-button>
      </div>
      <div class="col-adaptive operation-btn-col">
        <app-button
          [icon]="'compare_arrows'"
          [type]="btnTypes.Icon_Stroked"
          [size]="btnSizes.InOperationRow"
          [label]="'buttons.editPosition' | transloco"
          (onClick)="bulkEditQuestionsPositions()"
        ></app-button>
      </div>
      <div class="col-adaptive operation-btn-col">
        <app-button
          [icon]="'globe'"
          [type]="btnTypes.Icon_Stroked"
          [size]="btnSizes.InOperationRow"
          [label]="'buttons.editLanguage' | transloco"
          (onClick)="bulkEditQuestionsLanguage()"
        ></app-button>
      </div>
      <div class="col-adaptive operation-btn-col">
        <app-button
          [icon]="'delete'"
          [type]="btnTypes.Icon_Stroked"
          [size]="btnSizes.InOperationRow"
          [label]="'buttons.delete' | transloco"
          (onClick)="bulkDelete()"
        ></app-button>
      </div>
    </div>

    <div [ngClass]="{ hidden: isAnySelected }" [formGroup]="form">
      <div class="row-adaptive filters">
        <div class="col-adaptive main-filter-col">
          <app-search-input
            placeholder="{{ 'placeholders.filterByQuestionName' | transloco }}"
            formControlName="questionName"
            class="filter-control"
          ></app-search-input>
        </div>
        <div class="col-adaptive">
          <app-tui-multi-select-autocomplete
            formControlName="documentType"
            [autocompleteOptions]="docTypeItems"
            [placeholderKey]="'placeholders.filterByTypeOfDoc'"
          ></app-tui-multi-select-autocomplete>
        </div>
        <div class="col-adaptive">
          <app-tui-multi-select-autocomplete
            formControlName="position"
            [autocompleteOptions]="positionItems"
            [placeholderKey]="'placeholders.filterByMyPosition'"
          ></app-tui-multi-select-autocomplete>
        </div>
        <div class="col-adaptive">
          <app-tui-multi-select-autocomplete
            formControlName="languageId"
            [autocompleteOptions]="contentLanguages"
            optionLabel="displayName"
            [placeholderKey]="'placeholders.filterByLanguage'"
          ></app-tui-multi-select-autocomplete>
        </div>

        <div class="col-adaptive filter-btn-col">
          <app-button
            icon="close"
            [type]="btnTypes.Icon_Stroked"
            [size]="btnSizes.InFilterRow"
            [label]="'buttons.clearFilters' | transloco"
            (onClick)="clearFilters()"
          ></app-button>
        </div>
      </div>
    </div>

    <div
      [ngClass]="{ 'visible-flex': isAnySelected && filteredQuestionsCount }"
      class="row-adaptive selections-btns"
    >
      <div class="col-adaptive operation-btn-col">
        <span class="selected-items-count-info">
          <span class="text">
            <b>{{ selectedQuestionsCount }}</b> {{ textCountOfSelectedQuestions | transloco }}
          </span>
        </span>
      </div>
      <div class="col-adaptive operation-btn-col" *ngIf="filteredQuestionsCount > 1">
        <app-button
          [type]="btnTypes.Link"
          [size]="btnSizes.InOperationRow"
          [label]="textButtonsSelectAllQuestions | transloco: { count: filteredQuestionsCount }"
          (onClick)="selectAll()"
        ></app-button>
      </div>
      <div class="col-adaptive operation-btn-col">
        <app-button
          [type]="btnTypes.Link"
          [size]="btnSizes.InOperationRow"
          [label]="'buttons.unselectAll' | transloco"
          (onClick)="resetSelections()"
        ></app-button>
      </div>
    </div>
  }

  @if (dataRecieved && form) {
    <app-questions-list class="app-questions-table"></app-questions-list>
  }
</div>
