import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { QuestionsActions } from './actions';
import { catchError, combineLatest, map, mergeMap, of, switchMap } from 'rxjs';
import { AppState } from 'src/app/core/store/state';
import { DialogsActions } from '../../dialogs/store/actions';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { QuestionsApiService } from '../services/questions-api.service';
import { NotificationsActions } from '../../notifications/store/actions';

@Injectable()
export class FeatureEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private readonly router: Router,
    private questionsApiService: QuestionsApiService,
    private dialogRef: MatDialog,
    private translocoService: TranslocoService,
  ) {}

  getQuestions$ = createEffect(() =>
    combineLatest([this.actions$.pipe(ofType(QuestionsActions.getQuestions))]).pipe(
      map(() => {
        return QuestionsActions.loadQuestions();
      }),
    ),
  );

  loadQuestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuestionsActions.loadQuestions),
      switchMap(() => {
        return this.questionsApiService.getQuestions$().pipe(
          map((questions) => QuestionsActions.setQuestions({ questions })),
          catchError((response) => {
            return of(QuestionsActions.loadQuestionsFail());
          }),
        );
      }),
    ),
  );

  createQuestion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuestionsActions.createQuestion),
      mergeMap((props) =>
        this.questionsApiService.createQuestion$(props.model).pipe(
          map((question) => QuestionsActions.createQuestionSuccess({ question })),
          catchError((response) => {
            return of(QuestionsActions.createQuestionFail());
          }),
        ),
      ),
    ),
  );

  createQuestionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuestionsActions.createQuestionSuccess),
      map((props) => {
        this.store.dispatch(QuestionsActions.closeCreatingQuestionModal());

        this.store.dispatch(
          NotificationsActions.showNotification({
            message: this.translocoService.translate('text.questionAdded'),
            action: 'x',
          }),
        );
        this.dialogRef.closeAll();
        return QuestionsActions.getQuestions();
      }),
    ),
  );

  createQuestionCancel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuestionsActions.createQuestionCancel),
      map((props) => {
        // if (props.pristine) {
        return QuestionsActions.createQuestionCancelled();
        // } else {
        //   return DialogsActions.showDialog({
        //     data: {
        //       actionsKeys: ['yes', 'no'],
        //       content: '',
        //       title: this.translocoService.translate('titles.updatesWillNotBeSaved'),
        //     },
        //     callback: (action) => {
        //       if (action === 'yes') {
        //         this.store.dispatch(QuestionsActions.createQuestionCancelled());
        //         this.dialogRef.closeAll();
        //         return DialogsActions.setRef({ ref: null });
        //       } else {
        //         return DialogsActions.setRef({ ref: null });
        //       }
        //     },
        //   });
        // }
      }),
    ),
  );

  deleteQuestion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuestionsActions.deleteQuestion),
      map((props) => {
        return DialogsActions.showDialog({
          data: {
            actionsKeys: ['yes', 'no'],
            content: '',
            title: this.translocoService.translate('modalBody.confirmDeleteQuestion'),
          },
          callback: (action) => {
            if (action === 'yes') {
              return this.store.dispatch(
                QuestionsActions.deleteQuestionConfirmed({ id: props.id }),
              );
            } else {
              return of(
                DialogsActions.setRef({ ref: null }),
                QuestionsActions.deleteQuestionCancel(),
              );
            }
          },
        });
      }),
    ),
  );

  deleteQuestionConfirmed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuestionsActions.deleteQuestionConfirmed),
      mergeMap((props) => {
        return this.questionsApiService.deleteQuestion$(props.id).pipe(
          map(() => QuestionsActions.deleteQuestionSuccess()),
          catchError((response) => {
            return of(DialogsActions.setRef({ ref: null }), QuestionsActions.deleteQuestionFail());
          }),
        );
      }),
    ),
  );

  deleteQuestionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuestionsActions.deleteQuestionSuccess),
      map(() => {
        return QuestionsActions.getQuestions();
      }),
    ),
  );

  deleteQuestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuestionsActions.deleteQuestions),
      map((props) => {
        return DialogsActions.showDialog({
          data: {
            actionsKeys: ['yes', 'no'],
            content: '',
            title: this.translocoService.translate('modalBody.confirmDeleteQuestions'),
          },
          callback: (action) => {
            if (action === 'yes') {
              return this.store.dispatch(
                QuestionsActions.deleteQuestionsConfirmed({ ids: props.ids }),
              );
            } else {
              return of(
                DialogsActions.setRef({ ref: null }),
                QuestionsActions.deleteQuestionsCancel(),
              );
            }
          },
        });
      }),
    ),
  );

  deleteQuestionsConfirmed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuestionsActions.deleteQuestionsConfirmed),
      mergeMap((props) => {
        return this.questionsApiService.batchDeleteQuestions$(props.ids).pipe(
          map(() => QuestionsActions.deleteQuestionsSuccess()),
          catchError((response) => {
            return of(DialogsActions.setRef({ ref: null }), QuestionsActions.deleteQuestionsFail());
          }),
        );
      }),
    ),
  );

  deleteQuestionsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuestionsActions.deleteQuestionsSuccess),
      map(() => {
        return QuestionsActions.getQuestions();
      }),
    ),
  );

  loadQuestion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuestionsActions.loadQuestion),
      switchMap(({ id }) => {
        return this.questionsApiService.getQuestionById$(id).pipe(
          map((question) => QuestionsActions.setQuestion({ question })),
          catchError((response) => {
            return of(QuestionsActions.loadQuestionFail());
          }),
        );
      }),
    ),
  );

  editQuestionCancel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuestionsActions.editQuestionCancel),
      map((props) => {
        return QuestionsActions.editQuestionCancelled();
      }),
    ),
  );

  updateQuestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuestionsActions.updateQuestions),
      mergeMap((props) => {
        return this.questionsApiService.batchUpdateQuestions$(props.questions).pipe(
          map(() => QuestionsActions.updateQuestionsSuccess()),
          catchError((response) => {
            return of(QuestionsActions.getQuestions(), QuestionsActions.updateQuestionsFail());
          }),
        );
      }),
    ),
  );

  updateQuestionsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuestionsActions.updateQuestionsSuccess),
      map(() => {
        return QuestionsActions.getQuestions();
      }),
    ),
  );

  editQuestion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuestionsActions.editQuestion),
      mergeMap((props) =>
        this.questionsApiService.updateQuestion$(props.model, props.id).pipe(
          map((question) => QuestionsActions.editQuestionSuccess({ question })),
          catchError((response) => {
            return of(QuestionsActions.editQuestionFail());
          }),
        ),
      ),
    ),
  );

  editQuestionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuestionsActions.editQuestionSuccess),
      map((props) => {
        this.store.dispatch(QuestionsActions.closeEditingQuestionModal());

        this.store.dispatch(
          NotificationsActions.showNotification({
            message: this.translocoService.translate('text.questionUpdated'),
            action: 'x',
          }),
        );
        this.dialogRef.closeAll();
        return QuestionsActions.getQuestions();
      }),
    ),
  );
}
