import { SearchItemModel } from '../services/base-event-service';
import { FILTER_OPERATOR } from '../services/client-side-search.service';
import {
  BatchUpdateQuestionDto,
  GetQuestionDto,
  QuestionsSearchModel,
} from 'src/app/modules/questions/models/questions.model';
import {
  QUESTIONS_SEARCH_CRITERION,
  QuestionsSearchCriterionsFactory,
} from '../factories/questions-search-criterions.factory';
import { BulkEditValueModel } from 'src/app/shared/components/bulk-edit/app-bulk-edit-single-value-property/app-bulk-edit-single-value-property.component';

function getQuestionsFilterConditionsFromModel(
  model: QuestionsSearchModel,
  questionsSearchCriterionsFactory: QuestionsSearchCriterionsFactory,
): SearchItemModel[] {
  let conditions: SearchItemModel[] = [];

  if (!!model.questionName) {
    const criterion = questionsSearchCriterionsFactory.getById(QUESTIONS_SEARCH_CRITERION.NAME);
    const item = {
      criterion: criterion.id,
      operator: FILTER_OPERATOR.Contains,
      type: criterion.type,
      searchValue: model.questionName,
      controlName: criterion.controlName,
    } as SearchItemModel;

    conditions.push(item);
  }

  if (!!model.documentType) {
    const criterion = questionsSearchCriterionsFactory.getById(QUESTIONS_SEARCH_CRITERION.TYPE);
    const item = {
      criterion: criterion.id,
      operator: FILTER_OPERATOR.ContainsFromArray,
      type: criterion.type,
      searchValue: model.documentType.join(','),
      controlName: criterion.controlName,
    } as SearchItemModel;

    conditions.push(item);
  }

  if (!!model.position) {
    const criterion = questionsSearchCriterionsFactory.getById(QUESTIONS_SEARCH_CRITERION.POSITION);
    const item = {
      criterion: criterion.id,
      operator: FILTER_OPERATOR.ContainsFromArray,
      type: criterion.type,
      searchValue: model.position.join(','),
      controlName: criterion.controlName,
    } as SearchItemModel;

    conditions.push(item);
  }

  if (!!model.languageId) {
    const criterion = questionsSearchCriterionsFactory.getById(QUESTIONS_SEARCH_CRITERION.LANGUAGE);
    const item = {
      criterion: criterion.id,
      operator: FILTER_OPERATOR.ContainsFromArray,
      type: criterion.type,
      searchValue: model.languageId.join(','),
      controlName: criterion.controlName,
    } as SearchItemModel;

    conditions.push(item);
  }

  return conditions;
}

function getUpdatedQuestionsByBulkEditOperation(
  allQuestions: GetQuestionDto[],
  model: BulkEditValueModel,
): BatchUpdateQuestionDto[] {
  let questionsWithUpdates: BatchUpdateQuestionDto[] = [];

  allQuestions
    .filter((item) => model.selectedMainItemsIds.includes((item as any)[model.mainItemIdKey] + ''))
    .forEach((item) => {
      const clauseModel = {
        id: item.id,
        documentTypes: item.documentTypes,
        positions: item.positions,
        languageId: item.languageId,
      } as BatchUpdateQuestionDto;

      (clauseModel as any)[model.fieldToBeModifiedName] = model.checkedOptionValue;
      questionsWithUpdates.push(clauseModel);
    });

  return questionsWithUpdates;
}

export { getQuestionsFilterConditionsFromModel, getUpdatedQuestionsByBulkEditOperation };
