import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { QuestionsFeature } from './selectors';
import { BatchUpdateQuestionDto, GetQuestionDto, QuestionDto } from '../models/questions.model';

export const QuestionsActions = createActionGroup({
  source: QuestionsFeature,
  events: {
    'Get Questions': emptyProps(),
    'Load Questions Fail': emptyProps(),
    'Load Questions': emptyProps(),
    'Set Questions': props<{ questions: GetQuestionDto[] }>(),
    'Create Question': props<{ model: QuestionDto }>(),
    'Create Question Success': props<{ question: GetQuestionDto }>(),
    'Create Question Fail': emptyProps(),
    'Create Question Cancel': props<{ pristine: boolean }>(),
    'Create Question Cancelled': emptyProps(),
    'Clear Create Question Cancelled': emptyProps(),
    'Close Creating Question Modal': emptyProps(),
    'Delete Question': props<{ id: string }>(),
    'Delete Question Confirmed': props<{ id: string }>(),
    'Delete Question Success': emptyProps(),
    'Delete Question Fail': emptyProps(),
    'Delete Question Cancel': emptyProps(),
    'Update Questions': props<{ questions: BatchUpdateQuestionDto[] }>(),
    'Update Questions Success': emptyProps(),
    'Update Questions Fail': emptyProps(),
    'Delete Questions': props<{ ids: string[] }>(),
    'Delete Questions Confirmed': props<{ ids: string[] }>(),
    'Delete Questions Success': emptyProps(),
    'Delete Questions Fail': emptyProps(),
    'Delete Questions Cancel': emptyProps(),
    'Load Question': props<{ id: string }>(),
    'Load Question Fail': emptyProps(),
    'Set Question': props<{ question: GetQuestionDto }>(),
    'Edit Question': props<{ model: QuestionDto; id: string }>(),
    'Edit Question Success': props<{ question: GetQuestionDto }>(),
    'Edit Question Fail': emptyProps(),
    'Edit Question Cancel': props<{ pristine: boolean }>(),
    'Edit Question Cancelled': emptyProps(),
    'Clear Edit Question Cancelled': emptyProps(),
    'Close Editing Question Modal': emptyProps(),
  },
});
