import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { combineLatest, debounceTime, filter, tap } from 'rxjs';
import { QuestionsSearchCriterionsFactory } from 'src/app/core/factories/questions-search-criterions.factory';
import {
  Criterion,
  EventsServiceAction,
  EventsServiceData,
  SearchItemModel,
} from 'src/app/core/services/base-event-service';
import { getFiltersFormModel } from 'src/app/core/utils/filter.utils';
import { isPresent } from 'src/app/core/utils/isPresent';
import {
  getQuestionsFilterConditionsFromModel,
  getUpdatedQuestionsByBulkEditOperation,
} from 'src/app/core/utils/questions.utils';
import { selectDocTypes } from 'src/app/modules/doc-types/store/selectors';
import { PositionsApiService } from 'src/app/shared/services/positions-api.service';
import { AppButtonSize, AppButtonType } from 'src/app/shared/shared.model';
import { selectContentLanguages } from 'src/app/shared/store/selectors';
import { GetQuestionDto, QuestionsSearchModel } from '../../models/questions.model';
import { QuestionsActions } from '../../store/actions';
import { fromQuestions } from '../../store/selectors';
import { AddQuestionModalComponent } from '../modals/add-question-modal/add-question-modal.component';
import { QuestionsListEventsService } from '../questions-list/questions-list-events.service';
import { selectUser } from 'src/app/core/store/selectors';
import { AppBulkEditMultipleValuesPropertyComponent } from 'src/app/shared/components/bulk-edit/app-bulk-edit-multiple-values-property/app-bulk-edit-multiple-values-property.component';
import {
  AppBulkEditSingleValuePropertyComponent,
  BulkEditValueModel,
} from 'src/app/shared/components/bulk-edit/app-bulk-edit-single-value-property/app-bulk-edit-single-value-property.component';
import { constants } from 'src/app/core/constants/constants';
import { isPlural } from 'src/app/shared/utils';

@UntilDestroy()
@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrl: './questions.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionsComponent implements OnInit, OnDestroy {
  public btnTypes = AppButtonType;
  public btnSizes = AppButtonSize;
  public criterions: Criterion[] = this.questionsSearchCriterionsFactory.getAllItemsAsArray();
  public conditions: SearchItemModel[] | undefined;
  public docTypeItems: any;
  public positionItems: any;
  public form: FormGroup | undefined;
  public contentLanguages: any[] = [];
  public dataRecieved: boolean = false;
  public selectedIds: string[] = [];
  public filteredQuestions: GetQuestionDto[] | null = [];
  public allQuestions: GetQuestionDto[] = [];

  constructor(
    private readonly store$: Store,
    private titleService: Title,
    private bsModalService: BsModalService,
    private questionsListEventsService: QuestionsListEventsService,
    private questionsSearchCriterionsFactory: QuestionsSearchCriterionsFactory,
    private readonly positionsApiService: PositionsApiService,
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.selectedIds = [];
    this.waitUserReadyAndStart();
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.resetSelections();
  }

  waitUserReadyAndStart(): void {
    this.store$
      .select(selectUser)
      .pipe(untilDestroyed(this), filter(isPresent))
      .subscribe((user) => {
        this.startInitialization();
      });
  }

  startInitialization(): void {
    this.store$.dispatch(QuestionsActions.getQuestions());

    this.initForm();

    this.store$
      .select(fromQuestions.selectQuestions)
      .pipe(untilDestroyed(this), filter(isPresent))
      .subscribe((data) => {
        this.allQuestions = data;
        this.dataRecieved = !!data;
        this.cdr.markForCheck();
        setTimeout(() => {
          this.resetSelections();
          this.checkAndRestoreFilters();
        }, 1);
      });

    this.titleService.setTitle('AutoLex | Questions');
    this.questionsListEventsService.setFilterCriterions(this.criterions);
  }

  checkAndRestoreFilters() {
    const filters = this.questionsListEventsService.getFilters();

    if (filters?.length > 0) {
      const model = getFiltersFormModel(filters);
      this.form?.patchValue(model);
    } else {
      this.questionsListEventsService.onFilter([]);
    }
  }

  initForm() {
    combineLatest([
      this.store$.select(selectDocTypes).pipe(filter(isPresent)),
      this.positionsApiService.getPositions$(),
      this.store$.select(selectContentLanguages).pipe(filter(isPresent)),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([docTypes, postitions, contentLanguages]) => {
        this.docTypeItems = docTypes;
        this.positionItems = postitions;
        this.contentLanguages = contentLanguages;

        this.form = this.fb.group({
          questionName: [''],
          documentType: [null],
          position: [null],
          languageId: [null],
        });

        this.initSubscriptions();
        this.cdr.markForCheck();
      });
  }

  initSubscriptions(): void {
    this.form?.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(50),
        tap((model: QuestionsSearchModel) => {
          this.conditions = getQuestionsFilterConditionsFromModel(
            model,
            this.questionsSearchCriterionsFactory,
          );
        }),
      )
      .subscribe((value) => {
        this.questionsListEventsService.resetPagination();

        if (!!this.conditions) {
          this.questionsListEventsService.onFilter(this.conditions || []);
        }

        this.checkAndResetSelectAll();
      });

    this.questionsListEventsService
      .getSubject()
      .pipe(untilDestroyed(this))
      .subscribe((data: EventsServiceData) => {
        switch (data.action) {
          case EventsServiceAction.Select: {
            this.selectedIds = data.id;
            this.cdr.markForCheck();
            break;
          }
        }
      });

    this.questionsListEventsService
      .getDataSubject()
      .pipe(untilDestroyed(this))
      .subscribe((data: any[]) => {
        this.filteredQuestions = data; // filtered
        this.cdr.markForCheck();
      });
  }

  checkAndResetSelectAll() {
    if (this.conditions?.length === 0 && this.selectedIds.length === 0) {
      setTimeout(() => {
        this.resetSelections();
      });
    }
  }

  clearFilters(): void {
    this.form?.reset();
    this.cdr.markForCheck();

    if (this.selectedIds.length === 0) {
      setTimeout(() => {
        this.resetSelections();
      });
    }
  }

  bulkDelete(): void {
    this.store$.dispatch(QuestionsActions.deleteQuestions({ ids: this.selectedIds }));
  }

  selectAll(): void {
    this.questionsListEventsService.selectAll$.next(true);
  }

  resetSelections(): void {
    this.questionsListEventsService.resetSelections$.next(true);
    this.selectedIds = [];
    this.cdr.markForCheck();
  }

  addQuestion(): void {
    this.bsModalService.show(AddQuestionModalComponent, {
      initialState: {},
      class: 'modal-xl',
    });
  }

  bulkEditDocTypes(): void {
    const modal = this.bsModalService.show(AppBulkEditMultipleValuesPropertyComponent, {
      initialState: {
        titleKey: 'modalBody.bulkEditQuestionsTypes',
        countItemsWillBeUpdatedKey: this.textCountQuestionsWillBeUpdated,
        fieldToBeModifiedName: constants.fields.questionDocumentTypes,
        mainItemIdKey: constants.fields.questionId,
        mainItems: this.allQuestions,
        options: this.docTypeItems,
        selectedMainItemsIds: this.selectedIds,
      },
    });

    modal.content?.continue$
      .pipe(untilDestroyed(this), filter(isPresent))
      .subscribe((model) => this.updateQuestionsByBulkEditOperation(model));
  }

  bulkEditQuestionsPositions(): void {
    const modal = this.bsModalService.show(AppBulkEditMultipleValuesPropertyComponent, {
      initialState: {
        titleKey: 'modalBody.bulkEditQuestionsPositions',
        countItemsWillBeUpdatedKey: this.textCountQuestionsWillBeUpdated,
        fieldToBeModifiedName: constants.fields.questionUserPositions,
        mainItemIdKey: constants.fields.questionId,
        mainItems: this.allQuestions,
        options: this.positionItems,
        selectedMainItemsIds: this.selectedIds,
      },
    });

    modal.content?.continue$
      .pipe(untilDestroyed(this), filter(isPresent))
      .subscribe((model) => this.updateQuestionsByBulkEditOperation(model));
  }

  bulkEditQuestionsLanguage(): void {
    const modal = this.bsModalService.show(AppBulkEditSingleValuePropertyComponent, {
      initialState: {
        titleKey: 'modalBody.bulkEditQuestionsLanguage',
        countItemsWillBeUpdatedKey: this.textCountQuestionsWillBeUpdated,
        fieldToBeModifiedName: constants.fields.questionLanguage,
        mainItemIdKey: constants.fields.questionId,
        mainItems: this.allQuestions,
        options: this.contentLanguages,
        optionLabelKey: 'displayName',
        selectedMainItemsIds: this.selectedIds,
      },
    });

    modal.content?.continue$
      .pipe(untilDestroyed(this), filter(isPresent))
      .subscribe((model) => this.updateQuestionsByBulkEditOperation(model));
  }

  updateQuestionsByBulkEditOperation(model: BulkEditValueModel): void {
    let questionsWithUpdates = getUpdatedQuestionsByBulkEditOperation(this.allQuestions, model);

    this.store$.dispatch(QuestionsActions.updateQuestions({ questions: questionsWithUpdates }));
  }

  get isAnySelected(): boolean {
    return this.selectedIds && this.selectedIds.length > 0;
  }

  get filteredQuestionsCount(): number {
    return this.filteredQuestions?.length || 0;
  }

  get selectedQuestionsCount(): number {
    return this.selectedIds?.length || 0;
  }

  get textCountOfSelectedQuestions(): string {
    return isPlural(this.selectedQuestionsCount)
      ? 'text.countOfSelectedQuestions'
      : 'text.countOfSelectedQuestionsSingle';
  }
  
  get textButtonsSelectAllQuestions(): string {
    return isPlural(this.filteredQuestionsCount)
      ? 'buttons.selectAllQuestions'
      : 'buttons.selectAllQuestionsSingle';
  }

  get textCountQuestionsWillBeUpdated(): string {
    return isPlural(this.selectedQuestionsCount)
      ? 'text.countQuestionsWillBeUpdated'
      : 'text.countQuestionsWillBeUpdatedSingle';
  }
}
