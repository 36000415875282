@if (form) {
  <div class="modal-content" *let="isQuestionCreating$ | async as creating">
    <div class="modal-header">
      <h5 class="modal-title pull-left">{{ title }}</h5>
      <mat-icon (click)="onCancel()" class="">{{ 'close' }}</mat-icon>
    </div>

    <div class="modal-body" [formGroup]="form">
      <div class="row">
        <div class="col-sm-4 col-md-3 control-label">
          {{ 'labels.questionText' | transloco }}
        </div>
        <div class="col">
          <app-textarea
            placeholder="{{ 'placeholders.questionText' | transloco }}"
            formControlName="questionText"
          ></app-textarea>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4 col-md-3 control-label">
          {{ 'labels.questionLanguage' | transloco }}
        </div>
        <div class="col">
          <app-single-select-autocomplete
            class="w100"
            formControlName="languageId"
            [autocompleteOptions]="contentLanguages"
            optionLabel="displayName"
          ></app-single-select-autocomplete>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <strong>{{ 'labels.docsToApplyQuestion' | transloco }}</strong>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-sm-4 col-md-3 control-label">
          {{ 'labels.docTypes' | transloco }}
        </div>
        <div class="col d-flex">
          <app-multi-select-autocomplete
            class="w100"
            formControlName="documentTypes"
            [autocompleteOptions]="docTypeItems"
          ></app-multi-select-autocomplete>
          <app-button
            class="after-control"
            [type]="btnTypes.Flat"
            label="{{ 'labels.createNew' | transloco }}"
            [size]="btnSizes.Basic"
            (onClick)="openNewDocTypeForm()"
          ></app-button>
        </div>
      </div>

      @if (creatingNewDocType && addDocTypeForm) {
        <div class="arrow-up"></div>
        <div class="new-item-form" [formGroup]="addDocTypeForm">
          <div class="row">
            <div class="col">
              {{ 'text.createNewDocType' | transloco }}
            </div>
          </div>
          <div class="row mt-2">
            <div class="col">
              <app-input
                label="{{ 'labels.docType' | transloco }}"
                placeholder="{{ 'labels.docType' | transloco }}"
                formControlName="newDocumentType"
                [id]="'newDocumentType'"
              ></app-input>
            </div>
          </div>
          <div class="new-item-form__footer">
            <app-button
              [type]="btnTypes.Flat"
              label="{{ 'buttons.add' | transloco }}"
              [disabled]="addDocTypeForm.invalid"
              [size]="btnSizes.Basic"
              (onClick)="onConfirmNewDocType()"
            ></app-button>
            <app-button
              [type]="btnTypes.Stroked"
              label="{{ 'buttons.cancel' | transloco }}"
              [size]="btnSizes.Basic"
              (onClick)="onCancelNewDocType()"
            ></app-button>
          </div>
        </div>
      }

      <div class="row">
        <div class="col-sm-4 col-md-3 control-label">
          {{ 'labels.myPosition' | transloco }}
        </div>
        <div class="col">
          <app-multi-select-autocomplete
            class="w100"
            formControlName="positions"
            [autocompleteOptions]="positionItems"
          ></app-multi-select-autocomplete>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <app-button
        [type]="btnTypes.Flat"
        label="{{ 'buttons.add' | transloco }}"
        [disabled]="form.invalid || !!creating"
        [size]="btnSizes.Basic"
        (onClick)="onConfirm()"
      ></app-button>
      <app-button
        [type]="btnTypes.Stroked"
        label="{{ 'buttons.cancel' | transloco }}"
        [size]="btnSizes.Basic"
        (onClick)="onCancel()"
      ></app-button>
    </div>
  </div>
}
