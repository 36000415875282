import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { dataTableEvents } from 'src/app/core/constants/data-table-events';
import { EventsServiceAction, EventsServiceData } from 'src/app/core/services/base-event-service';
import { GetDocumentTypeDto } from 'src/app/modules/doc-types/models/doc-types.model';
import { DataTableItem } from 'src/app/shared/components/data-table/client-side-data-table/client-side-data-table.component';
import { DocTypesPipe } from 'src/app/shared/pipes/doc-types.pipe';
import { MyPositionsPipe } from 'src/app/shared/pipes/my-position.spipe';
import { ThreeDotsMenuPipe } from 'src/app/shared/pipes/three-dots-menu.pipe';
import { GetQuestionDto } from '../../models/questions.model';
import { QuestionsActions } from '../../store/actions';
import { selectQuestions } from '../../store/selectors';
import { EditQuestionModalComponent } from '../modals/edit-question-modal/edit-question-modal.component';
import { QuestionsListEventsService } from './questions-list-events.service';

@UntilDestroy()
@Component({
  selector: 'app-questions-list',
  templateUrl: './questions-list.component.html',
  styleUrl: './questions-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionsListComponent implements OnInit {
  public data: GetQuestionDto[] | undefined;
  public columns!: DataTables.ColumnSettings[];
  public sort!: (string | number)[][];
  public dataTableMenuItems!: DataTableItem[];
  public refreshDataTable: BehaviorSubject<any> = new BehaviorSubject(null);
  public docTypes: GetDocumentTypeDto[] = [];
  public questions: GetQuestionDto[] | null = [];

  constructor(
    private readonly store$: Store,
    public threeDotsMenuPipe: ThreeDotsMenuPipe,
    public docTypesPipe: DocTypesPipe,
    public myPositionsPipe: MyPositionsPipe,
    private translocoService: TranslocoService,
    private cdr: ChangeDetectorRef,
    public questionsListEventsService: QuestionsListEventsService,
    public bsModalService: BsModalService,
  ) {}

  ngOnInit(): void {
    this.initSubscriptions();
    this.fetchData();
  }

  fetchData(): void {
    combineLatest([this.store$.select(selectQuestions)])
      .pipe(untilDestroyed(this))
      .subscribe(([data]) => {
        this.initTable(data);
        this.cdr.detectChanges();
        setTimeout(() => {
          this.refreshDataTable.next(this.columns);
          this.cdr.detectChanges();
        }, 1);
      });
  }

  initSubscriptions(): void {
    this.translocoService.langChanges$.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.data) {
        this.initTable(this.data);
        this.refreshDataTable.next(this.columns);
      }
    });

    this.questionsListEventsService
      .getSubject()
      .pipe(untilDestroyed(this))
      .subscribe((data: EventsServiceData) => {
        switch (data.action) {
          case EventsServiceAction.Edit: {
            this.edit(data.id);
            break;
          }
          case EventsServiceAction.Delete: {
            this.delete(data.id);
            break;
          }
        }
      });
  }

  edit(id: string): void {
    this.store$.dispatch(QuestionsActions.loadQuestion({ id }));

    this.bsModalService.show(EditQuestionModalComponent, {
      initialState: {
        id,
      },
      class: 'modal-xl',
    });
  }

  delete(id: string): void {
    this.store$.dispatch(QuestionsActions.deleteQuestion({ id }));
  }

  initColumns(): void {
    const that = this;
    this.columns = Object.assign([
      {
        title: '<input type="checkbox" id="selectAll">',
        data: 'id',
        searchable: false,
        orderable: false,
        width: '30px',
        className: 'select-column',
        render: function (data: any, type: any, full: any) {
          return `<input type="checkbox" id="selectItem" ${dataTableEvents.common.checkedId}="${full.id}">`;
        },
      },
      {
        title: this.translocoService.translate('columns.keyQuestion'),
        data: 'text',
      },
      {
        title: this.translocoService.translate('columns.docType'),
        data: 'documentTypes',
        render: function (data: any, type: any, full: any) {
          const docTypesPipe = new DocTypesPipe(that.store$);
          let docTypesNames = '';

          docTypesPipe.transform(data).subscribe((value) => {
            docTypesNames = value;
          });

          return docTypesNames;
        },
      },
      {
        title: this.translocoService.translate('columns.myPosition'),
        data: 'positions',
        render: function (data: any, type: any, full: any) {
          return that.myPositionsPipe.transform(data);
        },
      },
      {
        width: '50px',
        data: 'id',
        searchable: false,
        orderable: false,
        render: function (data: any, type: any, full: any) {
          return that.threeDotsMenuPipe.transform(data, that.dataTableMenuItems);
        },
      },
    ]);
  }

  initTable(data: GetQuestionDto[] | null): void {
    this.initColumns();
    this.dataTableMenuItems = [
      {
        title: this.translocoService.translate('actions.editProperties'),
        event: dataTableEvents.common.edit,
        icon: 'edit_square',
      },
      {
        title: this.translocoService.translate('actions.remove'),
        event: dataTableEvents.common.delete,
        icon: 'delete',
      },
    ];

    this.sort = [[1, 'asc']];
    this.data = data || [];
  }
}
